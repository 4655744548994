<template>
    <div>
        <div class="page-title">问卷调查
            <button class="btn" @click="gotoCreate">新增问卷</button>
        </div>

        <a-table
                :rowKey="record => record.id || record.key"
                :data-source="table.data"
                :pagination="table.pagination"
                @change="tableChanged"
                :locale="{emptyText:'暂无问卷'}"
                class="members-table"
                :loading="table.loading"
        >

            <a-table-column title="#" data-index="id"></a-table-column>
            <a-table-column title="问卷标题" data-index="title"></a-table-column>
            <a-table-column title="在线地址" align="center" data-index="qrcode">
                <template slot-scope="val,item">
                    <a @click="activeMiniCode(item)">
                        <a-icon v-if="item" type="qrcode"/>
                    </a>
                </template>
            </a-table-column>
            <a-table-column title="创建时间" data-index="created_at">
                <template slot-scope="val">
                    {{ $formatTime(val) }}
                </template>
            </a-table-column>
            <a-table-column title="填写人数" align="center" data-index="submit_num">
                <template slot-scope="val,item">
                    <router-link :to="`/ast/qas/${item.id}/members`">{{ val }}</router-link>
                </template>
            </a-table-column>
            <a-table-column title="状态" data-index="enabled" align="center">
                <template slot-scope="enabled,item">
                    <a-switch :checked="!!enabled" @change=" e => changeStatus(item,e)"></a-switch>
                </template>
            </a-table-column>
            <a-table-column title="操作" data-index="op" align="center">
                <template slot-scope="val,item">
                    <div class="flex flex-center">
                        <div class="edit-btn" @click="editItem(item)">编辑</div>

                        <a-popover v-model="item.delete_visible" trigger="click" placement="topLeft">
                            <template #content>
                                <div class="del-pop">
                                    <div>
                                        注意：问卷删除后，所有涉及该问卷数据均会被清除且不可恢复，确认删除请在下方输入框输入“确认删除”
                                    </div>
                                    <div>
                                        <input @keyup="$forceUpdate()"
                                               v-model="item.delete_confirm"
                                               class="del-input"
                                               type="text">
                                    </div>
                                    <div class="del-pop-btns">
                                        <div @click="item.delete_visible = false " class="cancel-btn del-pop-btn">取消
                                        </div>
                                        <div class="confirm-btn del-pop-btn"
                                             @click="confirmDel(item)"
                                             :class="{active:item.delete_confirm === '确认删除'}">确定
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div class="del-btn" @click="(!item.delete_visible) && (item.delete_confirm = '') ">删除
                            </div>
                        </a-popover>
                    </div>
                </template>
            </a-table-column>
        </a-table>


        <img v-if="miniCode" @load="qrLoad" class="preload-img" :src="miniCode" alt="">

        <a-modal v-model="showMiniCodeModal" :footer="null" width="406px">
            <template #title v-if="detail">{{ detail.title }}</template>
            <div class="mini-qrcode-container flex flex-center">
                <small>问卷填写二维码</small>
                <br>
                <img v-if="miniCode" :src="miniCode" alt="">
                <br>
                <a class="btn" :href="miniCode">下载</a>
            </div>
        </a-modal>

    </div>
</template>

<script>

import api from "@/repo/api";
import moment from "moment/moment";

export default {
    components: {},
    data() {
        return {
            query: {
                status: 1
            },
            detail: null,
            showDetailModal: false,
            showMiniCodeModal: false,
            table: {
                loading: true,
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                    showTotal: (total, range) => {
                        return `当前${range[0]}-${range[1]}，共 ${total} 个案例`;
                    },
                },
                data: []
            },
            miniCode: undefined,
        }
    },
    mounted() {
        this.getFirstList()
    },
    methods: {
        activeMiniCode(item) {
            this.detail = item;

            this.$loading.show()

            setTimeout(() => {

                this.miniCode = this.$qrcode(
                    "packagesC/questionnaireShare/questionnaireShare?id=" + item.id,
                    `问卷小程序码-${item.id}`
                );

            }, 50)
        },
        qrLoad() {
            this.$loading.hide()
            this.showMiniCodeModal = true
        },
        gotoCreate() {
            this.$router.push('/ast/qas/create')
        },
        showDetail(item) {
            this.detail = item
            this.showDetailModal = true
        },
        formatTime(time) {
            return moment(time).format('yyyy-MM-DD HH:mm')
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        getList() {

            this.table.loading = true

            api.get('/ast-qa/list', this.query, (data) => {

                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false

            })
        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        },
        editItem(item) {
            this.$router.push(`/ast/qas/${item.id}`);
        },
        confirmDel(item) {

            if (item.delete_confirm !== '确认删除') return;

            this.$loading.show()

            api.post('/ast-qa/delete', {id: item.id}, res => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code === 0) {
                    item.delete_visible = false
                    this.getList()
                }

            })
        },
        changeStatus(item, val) {

            this.$loading.show()

            api.post('/ast-qa/enable', {id: item.id, enable: val ? 1 : 0}, res => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code === 0) {
                    this.getList()
                }

            })
        }
    }
}
</script>
<style lang="less" scoped>

.members-table /deep/ table {
    .ant-table-thead > tr > th {
        background: transparent;
        padding: 12px 12px;
    }

    .ant-table-column-title {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
    }

    .ant-table-tbody > tr > td {
        font-size: 14px;
        font-weight: 400;
        color: #606266;
        line-height: 14px;
        padding: 12px 12px;
    }
}


.op-btn {
    font-size: 12px;
}


.mt-4 {
    margin-top: 8px;
}

.page-title {
    .btn {
        float: right;
    }
}

.edit-btn, .del-btn {
    font-size: 14px;
    color: #FBB03B;
    cursor: pointer;
}

.del-btn {
    color: #F5606D;
    padding-left: 12px;
    border-left: 1px solid rgba(153, 153, 153, 1);
    margin-left: 12px;
}

.del-input {
    padding: 4px 8px;
    margin-top: 8px;
    border: 1px solid #D9D9D9;
    width: 100%;
}

.del-pop {
    width: 160px;
}

.del-pop-btns {

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;

    .del-pop-btn {
        font-size: 14px;
        width: 72px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        border: 1px solid #EEEEEE;
        color: #333333;
    }
}

.confirm-btn {
    margin-left: 16px;
}

.confirm-btn.active {
    border: 1px solid #F5606D;
    color: #F5606D;
}

.mini-qrcode-container {
    flex-direction: column;

    img {
        width: 256px;
        height: 256px;
    }
}

</style>